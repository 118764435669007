import styled from "styled-components";

const Container = styled.div`

  header{
    width: 100%;
    display: flex;
    justify-content: center;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 500px;  
        }
      }
    }

    @media(max-width: 752px){
      header {
        margin:0;
        padding:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div{
          img{
        width: 10rem;  
        }
        &:last-child{
          img{
        width: 15rem;
        margin-left: 0;
        margin-bottom: 100px;
        }
        }
      }
    }}
    @media(max-height: 640px){
      header {
        margin:10px 0 0 0;
        padding:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div{
          img{
        width: 10rem;
        margin-top: 56px;
        }
        &:last-child{
          img{
        width: 15rem;
        margin-left: 0;
        margin-bottom: 36px;
        }
        }
      }
    }
}

`
export {Container}