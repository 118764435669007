import styled from "styled-components";

const Container = styled.div`

  margin:0;
  padding:0;
  box-sizing: border-box;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .modal-form{
    background-color: #245C3B;
    width: 37.5rem;
    min-height: 43.75rem;
    border-radius: 15px;
    padding: 36px;
    

  }

  @media(max-width: 740px){
    height:100vh;
    h2 {
      font-size: 1.25rem;
    }
    .modal-form{
      height: 100vh;
      width: 100vw;
      border-radius: 0;

    }
  }

  @media(max-height: 640px){
    display: flex;

    .modal-form{
    background-color: #245C3B;
    border-radius: 0px;
  }
}

`

export {Container}