import {createSlice} from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'client',
  initialState:{
    codigo:'',
    nome:'',
    email:'',
    telefone:'',
  },
  reducers:{
    setCodigo: (state, action)=> {
      state.codigo = action.payload;
    },
    setNome: (state, action)=> {
      state.nome = action.payload;
    },
    setEmail: (state, action)=> {
      state.email = action.payload;
    },
    setTelefone: (state, action)=> {
      state.telefone = action.payload;
    },
  }
})

export const {setCodigo, setNome, setEmail,setTelefone} = slice.actions

export default slice.reducer