import React from "react"
import { HeaderComponent } from "../../components/HeaderComponent/HeaderComponent"
import { InputComponent } from "../../components/InputComponent/Input-component"

import { Container } from "./styles"


 export const Home = () =>{
  return(

    <Container>
      <div className="modal-form">
        <div>
          <HeaderComponent/>
        </div>
        <div>
          <InputComponent
          placeholder='Buscar ingresso'
          />
        </div>
      </div>
    </Container>

    
  )
}
