import React from "react"
import { Container } from "./styles"
import Logo1Img from "../../assets/logo-codornada.png"

export const HeaderComponent = () =>{

  function restorePage () {
    return setTimeout(window.location.reload(true))
  }
 return(
  <Container>
      <header>
        <div>

            <img src={Logo1Img} alt="" onClick={restorePage} style={{cursor: "pointer"}}/>
        </div>
      </header>
    </Container>
 )
}