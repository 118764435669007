import styled from "styled-components";

export const Container = styled.div`
  margin:0;
  padding:0;
  box-sizing: border-box;
  display: flex;
  background-color: #245C3B;
  align-items: center;
  justify-content: center;
  height: 100vh;

  section{
    background: #7B39AA;
    padding: 36px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;

    h2{
      margin-bottom: 36px;
    }

    input{
      margin-bottom: 16px;
      height: 40px;
      border:0;
      padding: 16px;
    }

    button{
      width: 100%;
      margin-top: 16px;
      height: 40px;
      font-size: 1.25rem;
      color: #fff;
      background: transparent;
      border: solid 2px #FFF;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
        background: #FFA100;
        border: #245c3b;
      }
    }
  }

`