/* eslint-disable no-unused-vars */
import React from "react"
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import App from '../../components/QRReader/App'
import { Container } from './styles';
import Modal from 'react-modal';
import { BsSearch } from "react-icons/bs";
import toast from "react-hot-toast";



const Dashboard = (props) =>{

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '350px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [val, setVal] = useState('');
  const [userData, setUserData] = useState('')
  const [nome,setNome] = useState('')
  const [sobrenome,setSobrenome] = useState('')
  const [celular,setCelular] = useState('')
  const [email,setEmail] = useState('')
  const [pulseira, setPulseira] = useState('')
  const [load, setLoaded] = useState(false)
  const [styleInputSearch, setStyleInputSearch] = useState(false)

  if(pulseira.length === 5){

  }
  
  
  //QRCode part------
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    openModal()
    // @ts-ignore
    setDecodedResults(prev => [...prev, decodedResult]);
  };
  
  
  function openModal() {
    setIsOpen(true);
  }

const procuraPulseiraBotao = async(event) =>{
  event.preventDefault()
  openModal()
   try {
    axios
    //.get(`${process.env.REACT_APP_API}/search?codigo=${pulseira}`) ///DESCOMENTAR ESSA LINHA E APAGAR A DEBAIXO NO DEPLOY
    .get(`${process.env.REACT_APP_API}/search?codigo=${pulseira}`)
    .then(async (response) => setUserData(response.data))
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err.status);
    });
    if(userData === null) {
      toast.error('Pulseira inválida.')
    }
    return
  } catch (error) {
    alert('Pulseira não cadastrada')
  }

  setIsOpen(true);
}

let subtitle

function afterOpenModal() {
  // references are now sync'd and can be accessed.
  subtitle.style.color = '#1d1b1b';
}

function closeModal() {
  setIsOpen(false);
  setPulseira('')
}


if(userData === null){
  toast.error("Código inválido!")
}

  
  return (
    <>
      <Container>
       <h2>Scaneie o QRCode ou digite no campo abaixo:</h2>
       <form>
       <input
       type='text'
       value={pulseira}
       onChange={(e) => setPulseira(e.target.value)}
       maxLength={6}
       minLength={6}
       required
       />
          <button onClick={procuraPulseiraBotao}>
            <BsSearch
              size={40}
            />
          </button>
       </form>

       <App />
       
       {<Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal}
        style={{
          position: 'absolute',
          border:0,
          background:'transparent',
          fontSize: '1.5rem',
          fontWeight:'bold',
          margin:0,
          padding:0,
          right:'15px',
          cursor: 'pointer'
          }}>X</button>
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>CheckIn</h2>
        
        <div>I am a modal</div>
          {userData ? 
          <>
        <form style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          flexDirection: 'column'

        }}>
          <input
          placeholder=""
          value={userData.nome}
          onChange={(e) => setNome(e.target.value)}
          style={{height: '45px',
          padding:'16px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '1rem',
          border: 'none',
          background:'#dbd8d8',
          marginBottom: '18px'
        }}
          />
          <input
          placeholder=""
          value={userData.sobrenome}
          onChange={(e) => setSobrenome(e.target.value)}
          style={{height: '45px',
          padding:'16px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '1rem',
          border: 'none',
          background:'#dbd8d8',
          marginBottom: '18px'
        }}
          />
          <input
          placeholder=""
          value={userData.celular}
          onChange={(e) => setCelular(e.target.value)}
          style={{height: '45px',
          padding:'16px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '1rem',
          border: 'none',
          background:'#dbd8d8',
          marginBottom: '18px'
        }}
          />
          <input
          placeholder=""
          value={userData.email}
          onChange={(e) => setEmail(e.target.value)}
          style={{height: '45px',
          padding:'16px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '1rem',
          border: 'none',
          background:'#dbd8d8',
          marginBottom: '18px'
        }}
          />

          <button
          style={{
            width: '100%',
            height: '45px',
            border: 'solid 1px',
            background: '#5aca63',
            borderRadius: '4px',
            color: '#fff',
            fontWeight: 'bold',
            fontSize:'1.5rem'
          }}
          >Fazer checkin</button>
        </form>
          </>
          : <p>carregando</p> 
          
          }
      </Modal>}
       </Container>

       
       
    </>
  )
}

export {Dashboard}