import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Container, Form } from "./styles"
import {IMaskInput} from "react-imask"
import CircleLoader from "react-spinners/CircleLoader"
import axios from "axios"
import toast, {Toaster} from "react-hot-toast"
import { ClipLoader } from "react-spinners"
import TelefoneBrasileiroInput from "react-telefone-brasileiro";



const FormComponent = (props) =>{

  const user = useSelector((state) => state.user)

  const [userData, setUserDate] = useState({})

  const[nome, setNome] = useState('')
  const[sobrenome, setSobrenome] = useState('')
  const[email, setEmail] = useState('')
  const[celular, setCelular] = useState('')

  const[loaded, setLoaded] = useState(false)
  const[btnLoaded, setBtnLoaded] = useState(true)

  const validEmail = /\S+@\S+\.\S+/

  useEffect(()=>{
    axios
    //.get(`http://177.87.72.249:7777/search?codigo=${user.codigo}`)
    .get(`${process.env.REACT_APP_API}/search?codigo=${user.codigo}`)
    .then(async (response) => setUserDate(response.data))
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err.status);
    });
    return
},[])



const handleUpdate = async (event) => {
  event.preventDefault()

  if(!nome || nome.length < 3){
    toast.error('    Insira um nome válido', {style:{padding: '16px'}, icon:'❌'})
    return 
  }

  if(!sobrenome || sobrenome.length < 4){
    toast.error('Insira um sobrenome válido',{style:{padding: '16px', icon:'❌'}})

    return
  }

  if(!celular || celular.length < 14) {
    toast.error('Insira um número de telefone válido', {style:{padding: '16px'}, icon:'❌'})
    return
  }

  if(!email || validEmail.test(email) === false ){
    toast(' Insira um email válido', {style:{padding: '16px'}, icon:'❌'})
    return
  }

  
  
  const response = await axios
  .put(`${process.env.REACT_APP_API}?codigo=${user.codigo}`, {
    nome,
    sobrenome,
    celular,
    email
  })
  .then(async (response) => setUserDate(response.data),
  toast.success('Salvo com sucesso!'),
  setLoaded(true)
  )
  .catch((err) => {
    console.error("ops! ocorreu um erro" + err.status);
  });

  


    setBtnLoaded(false)    
    return setTimeout(window.location.reload(true),  setLoaded(true))
}


  return(
    <>
<Container>
      <Toaster/>
        <div>
        <h2>CADASTRO DE INGRESSO</h2>
        <p style={{textTransform: 'uppercase'}}>{user.codigo}</p>
      </div>
      <Form action="/" method="PUT">
      
        <input
        type="text"
        placeholder={userData.nome === '' ? 'Nome' : userData.nome}
        style={{margin: '8px 0'}}
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        maxLength={15}/>

        <input
        type="text"
        placeholder={userData.sobrenome === '' ? 'Sobrenome' : userData.sobrenome}
        style={{margin: '8px 0'}}
        value={sobrenome}
        onChange={(e) => setSobrenome(e.target.value)}
        maxLength={15}/>

        <TelefoneBrasileiroInput
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                temDDD
                separarNono
                separarDDD
                placeholder={userData.celular === '' ? 'Celular' : userData.celular}
            />

        <IMaskInput
        type="email"
        placeholder={userData.email === '' ? 'E-mail' : userData.email}
        style={{margin: '8px 0'}}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        maxLength={30}/>

        {
          loaded ? <Button style={{display:"flex", alignItems:"center", justifyContent:"center", background:"#C6C6C6"}}><ClipLoader size={35} color="#1177ca"/></Button> : <Button
          onClick={handleUpdate} 
          style={{
            marginTop: '26px',
            background: `${nome && sobrenome && celular && validEmail.test(email) ? "#BFEC9D" : "#B2C1B8"}`,
            color:`${nome && sobrenome && celular && validEmail.test(email) ? "#000000" : "#646464"}`,
            cursor: `${nome && sobrenome && celular && validEmail.test(email) ? "pointer" : "not-allowed"}`
          }}
          >Salvar</Button>
        }
        
      </Form>
    </Container>
    </>
  )
}

export { FormComponent }