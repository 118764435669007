import styled from "styled-components";

const Container = styled.div`
  margin-top: 10rem;
  align-items: center;
  h2{
    text-align: center;
  }
  div{
    display: flex;
    flex-direction: row;

  }

  h2{
    color: #77D62D;
  font-size: 2.3rem;
  font-weight: 400;
  margin-bottom: 50px;;
  }

  p{
    font-size: 24px;
    color: #77D62D;
    line-height: 60px;
    margin-top: 41px;
  }

  @media(max-width: 740px){
  margin-top:16px;
  p{
    font-size: 1rem;
  }
}

`

const Input = styled.input.attrs((props) => ({
  type: "input"
}))`
width: 100%;
height: 70px;
border: solid 4px #77D62D;
  border-radius: 15px 0 0 15px;

  background-color: ${(props) =>
    props.validationInput ? "#7FDF7D" : "#c6e2c5"};
  font-size: 1rem;
  border-right: 0;
  padding: 1.5rem 0.5rem;
  outline: none;
  text-transform: uppercase;
  padding: 0 16px;

  @media(max-width: 740px){
  height: 45px;
  padding: 0;
}
`

const ActionButton = styled.div`

  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 4px #77D62D;
  border-left: 0;
  cursor: pointer;
  border-radius: 0 15px 15px 0;
  div {
    display: flex;
    align-items: center;
  }
  @media(max-width: 740px){
  height: 45px;
}
  
`;


export {Container, Input, ActionButton };
