import styled from "styled-components";

export const Container = styled.div.attrs({
  type: 'div'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  h2{
    margin: 36px;
  }

  
  form{
    width: 100%;
    max-width: 600px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
    

    input{
      width: 100%;
      height: 70px;
      border-radius:4px;
      font-size: 2rem;
      padding: 16px;
      background-color: #cacaca;
      border: none;
      text-transform: uppercase;
      &:focus{
        outline: 2px solid #1390cf;
      }
    }
    button{
      background: transparent;
      border:none;
      position: absolute;
      right: 16px;
      transform: rotateY(180deg);
      cursor: pointer;
      color: #7777;
      transition: .3s;
      &:hover{
        color: #1390cf;
      }
    }
  }
`