import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  margin-top: 5rem;

  h2{
    font-size: 1.75rem;
    color: #77D62D;
  }

  div{
    display: flex;
    align-items: center;
    justify-content: center;

    p{
      color: #fff;
      padding: 6px 13px;
      margin-left: 4px;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  @media(max-width: 752px){
  margin-top: 0;
  h2{
    font-size: 1rem;
  }
  div{
    flex-direction: column;
    p{
      font-size: 1.5rem;
    }
  }
}

@media(max-height: 640px){
  margin-top: 8px;
  h2{
    margin: 0;
  }
  margin: 0;
  padding: 0;
}
  
`

const Form = styled.form.attrs((props) => ({
  type: "form"
}))`
display: flex;
flex-direction: column;
margin-top: 36px;
input{
  width: 100%;
  max-width: 500px;
  min-width: 350px;
  border-radius: 15px;
  height: 70px;
  border: solid 4px #77D62D;
  background-color: #B2C1B8;
  font-size: 1.5rem;
  padding: 0.5 ;
  box-shadow: inset 1px 1px 3px 1px #cacaca;
  outline: none;
  margin: 8px 0;
  padding: 0 16px;

}

@media(max-width: 752px){
  input{
    min-width: 250px;
    height: 45px

  }
}
@media(max-height: 750px){ //somente o formulário

  form{
    margin: 16px;
    input{
    min-width: 250px;
    height: 45px
  }
  }
}
`

export const Button = styled.button `
  height: 70px;
  background-color: #B2C1B8;
  border: solid 4px #77D62D;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 36px;
  font-size: 1.5rem;

  @media(max-width: 752px){
    height: 45px

}

@media(max-height: 750px){
  height: 45px;
  min-width: 250px;
}

`


export {Container, Form };
