// @ts-nocheck

import React, { useState } from 'react';
import './App.css';
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
// @ts-ignore
import ResultContainerPlugin from './ResultContainerPlugin.jsx';
import Modal from 'react-modal';
import axios from 'axios';
import CircleLoader from "react-spinners/CircleLoader"

// @ts-ignore
const App = (props) => {
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      const [modalIsOpen, setIsOpen] = useState(false);
      const [userData, setUserdata] = useState('')
      // @ts-ignore
      const [nome,setNome] = useState('')
      // @ts-ignore
      const [sobrenome,setSobrenome] = useState('')
      // @ts-ignore
      const [celular,setCelular] = useState('')
      // @ts-ignore
      const [email,setEmail] = useState('')
      
      function openModal() {
        setIsOpen(true);
      }


    // @ts-ignore
    const [decodedResults, setDecodedResults] = useState([]);

    
    // @ts-ignore
    const onNewScanResult = (decodedText, decodedResult) => {
        if(decodedResult.decodedText !== null){
            openModal()
            try {
                axios
                .get(`${process.env.REACT_APP_API}/search?codigo=${decodedResult.decodedText}`)
                .then(async (response) => setUserdata(response.data))
                .catch((err) => {
                  console.error("ops! ocorreu um erro" + err.status);
                });

                // @ts-ignore
                setNome(userData.nome)
                setSobrenome(userData.sobrenome)
                setCelular(userData.celular)
                setEmail(userData.email)
                return
              } catch (error) {
                alert('Pulseira não cadastrada')
              }
        }
        console.log("arquivo App", decodedResult.decodedText);
        // @ts-ignore
        setDecodedResults(prev => [...prev, decodedResult]);
    };

        console.log(userData)
      let subtitle

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }
      
      function closeModal() {
        setIsOpen(false);
      }

      

    return (
        <>
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
<h2 ref={(_subtitle) => (subtitle = _subtitle)}>CheckIn</h2>
        <button onClick={closeModal}>close</button>
        {!userData  ? <CircleLoader color='#36d7b7"'/>
        :
        <ul>
        <li>
            {userData.nome}
        </li>
        <li>
            {userData.sobrenome}
        </li>
        <li>
            {userData.celular}
        </li>
        <li>
            {userData.email}
        </li>
    </ul>
        }

      </Modal>
            <section className="App-section">

                <Html5QrcodePlugin
                    fps={10}
                    qrbox={200}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />


            </section>
        </>
    );
};

export default App;
