import "./App.css"
import { Home } from "./pages/Home";
import { NotFoundPage } from "./pages/Not-found";
import { Container } from "./global-styles";
import {
  Routes,
  Route
} from "react-router-dom";
import React from "react";

import { LoginPage } from "./pages/Login-page";
import { Dashboard } from "./pages/Dashboard";




export default function App(props) {

  

  return (
    <Container>
       <Routes>
        <Route index element={<Home />} />
        <Route path="/codornada-login" element={<LoginPage/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Container>
  );
}
