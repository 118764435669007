import React from "react";
import { useState } from "react";
import { Container, ActionButton, Input } from "./styles";
import { BsCheck2 } from "react-icons/bs";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { FormComponent } from "../FormComponent/FormComponent";
import { setCodigo } from "../../redux/reducers/userReducer";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";


function InputComponent(props) {

  const dispatch = useDispatch()

  const[user, setUser] = useState('')
  const [validation, setValidation] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [button, setButton] = useState("")
  const [btnload, setBtnload] = useState(false)

 async function handleClick (){
  setBtnload(true)
  axios
         .get(`${process.env.REACT_APP_API}/search?codigo=${inputValue}`)
         .then((response) => setUser(response.data))
         .catch((err) => {
           console.error("ops! ocorreu um erro" + err.status);
         });
         return
        }
        
        console.log(btnload)

  if(user === null){
    toast.error("Código do ingresso não encontrado. Verifique o código digitado e tente novamente.", {style:{
           padding: '16px'
         }})
    setBtnload(false)
    setUser(0)
    return <InputComponent />
  }

  if(typeof user === 'object'){
    dispatch(
      setCodigo(user.codigo)
      )
    return <FormComponent/>
  }

  

  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
    if (inputValue.length === 5) {
      setValidation(true);
      setButton("active")
    } else {
      setValidation(false);
      setButton("active")
    }
  };

  return (

    <>
      <Container>
        <Toaster />
        <h2>BUSCAR INGRESSO</h2>
        <div>
        <Input
          name={props.name}
          placeholder={props.placeholder}
          value={inputValue}
          onChange={onChangeHandler}
          // validationInput={validation}
          maxLength={6}
          style={{
            background: `${!validation ? "#B2C1B8" : "#BFEC9D"}`,
            
            padding: '0 16px',
            textAlign:'center',
            fontSize: '1.7rem',
            fontWeight: `${!validation ? '' : 'bold'}`,
            color: `${!validation ? '#333' : '#FFF'}`
          }}
        />
        <ActionButton type="submit" 
        style={{
          background: `${!validation ? "#B2C1B8" : "#BFEC9D"}`,
          padding: "0 16px",
          cursor: `${!validation ? "not-allowed" : "pointer"}`
        }}>  
            <div variant={button} onClick={handleClick}>
            {btnload ? <ClipLoader color="#1177ca"/> : <BsCheck2
              style={{
                fontSize: "50",
                color: `${!validation ? "#9F9F9F" : "#77D62D"}`,
                
              }}
            />}
            </div>
            </ActionButton>
        </div>
        <div>
          <p>
          Caso esteja com problemas na validação do seu ingresso entre em contato com o CAEX através do whats (49) 9.9837-0457.
          </p>
        </div>
      </Container>
    </>
  );
}

export { InputComponent };
