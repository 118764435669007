import React from "react"
import axios from 'axios';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Dashboard } from '../Dashboard';
import {Navigate, useNavigate } from 'react-router-dom';
import { Container } from './styles';
import {Input, useNavbar} from "@nextui-org/react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { ClipLoader } from "react-spinners";
import { BsDisplay } from "react-icons/bs";

const LoginPage = () =>{

  const navigate = useNavigate();

  const [val, setVal] = useState('');
  const [user,setUser] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [load, setLoad] = useState(false)


  const handleSubmit = async e => {
    const user = { email, password };
    // enviar o nome de usuário e a senha ao servidor
    setLoad(true)
    const response = await axios.post(
 
      `${process.env.REACT_APP_API}/autenticar`, ////DESCOMENTAR NO DEPLOY
      user, 
    );

    console.log(user)

    setUser(response.data.authenticate)
    // armazenar o usuário em localStorage

  };

  if(user){
    navigate("/dashboard");
    return
  }else{
  return(
    
    <Container>
      <Toaster />
      <section>
        <h2>Login</h2>
        <input type="email" placeholder="Digite seu e-mail" onChange={(e)=> setEmail(e.target.value)}/>
        <input type="password" placeholder="Digite sua senha" label="Senha" onChange={(e)=> setPassword(e.target.value)}/>
        <button 
        onClick={handleSubmit} 
        type='submit'
        style={{
          display: "flex",
          alignItems: 'center',
          justifyContent:'center'
        }}
        >
          {load ? <ClipLoader color="#333"/> : 'Entrar'}
        </button>
      </section>
    </Container>
  )
}}

export {LoginPage}